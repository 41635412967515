/** @jsx jsx */

import { jsx } from 'theme-ui'

import Footer from '../components/footer'
import Halo from '../components/halo'
import Header from '../components/header'
import { getAmountOfCharacters } from '../utilities'

const About = () => {
  return (
    <div sx={{ display: `grid`, fontFamily: `body`, gap: 3 }}>
      <Halo description="Character characters project details" title="About" />
      <Header />
      <section sx={{ py: 5, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <h1
            sx={{
              my: 0,
              fontWeight: `600`,
              fontSize: [`2rem`, `2.5rem`, `3rem`],
              letterSpacing: `-0.02em`,
            }}
          >
            About
          </h1>

          <p sx={{ mt: 2, mb: 4, fontSize: [`1rem`, `1.25rem`, `1.5rem`] }}>Character characters project details</p>
        </div>
      </section>

      <main sx={{ py: 4, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <h2 id="looking-forward" sx={{ my: 0 }}>
            <a
              href="#looking-forward"
              sx={{
                color: `action`,
                textDecoration: `none`,

                ':hover, : focus': {
                  textDecoration: `underline`,
                },
              }}
            >
              Looking forward
            </a>
          </h2>

          <p sx={{ mb: 0 }}>
            Characters, being unique unicode patterns that are baked into their own smart contracts, serve as a
            blueprint, and are just the beginning.
          </p>

          <h2 id="floor" sx={{ mt: 5, mb: 0 }}>
            <a
              href="#floor"
              sx={{
                color: `action`,
                textDecoration: `none`,

                ':hover, : focus': {
                  textDecoration: `underline`,
                },
              }}
            >
              Floor
            </a>
          </h2>

          <p sx={{ mb: 0 }}>
            <span sx={{ borderRadius: 3, py: 1, px: 2, color: `white`, bg: `royalblue` }}>0.00000 ETH</span>
          </p>

          <p sx={{ mb: 0 }}>Start your character collection.</p>

          <h2 id="team" sx={{ mt: 5, mb: 0 }}>
            <a
              href="#team"
              sx={{
                color: `action`,
                textDecoration: `none`,

                ':hover, : focus': {
                  textDecoration: `underline`,
                },
              }}
            >
              Team
            </a>
          </h2>

          <p sx={{ mb: 0 }}>c_C</p>

          <h2 id="anatomy-of-a-character-character" sx={{ mt: 5, mb: 0 }}>
            <a
              href="#anatomy-of-a-character-character"
              sx={{
                color: `action`,
                textDecoration: `none`,

                ':hover, : focus': {
                  textDecoration: `underline`,
                },
              }}
            >
              Anatomy of a character character
            </a>
          </h2>

          <ul
            sx={{
              display: `grid`,
              gap: 3,
              pl: 0,
              mt: 3,
              mb: 0,
              listStyleType: `none`,
            }}
          >
            <li>
              Each character is created from 91 unicode characters, including spaces (7 rows × 13 columns = 91 unicode
              characters).
            </li>
            <li>
              Each character is created with five layers (<i>head</i>, <i>eyes</i>, <i>nose</i>, <i>mouth</i>, and{' '}
              <i>chin</i>).
            </li>
          </ul>

          {/* TODO: Choose existing character and update links */}
          <a
            href="/characters/1"
            sx={{
              mt: 4,
              display: `block`,
              textDecoration: `none`,
              color: `inherit`,

              '> pre > div > span': {
                transition: `box-shadow 750ms, background-color 750ms`,
              },

              ':hover > pre > div > span, :focus > pre > div > span': {
                boxShadow: `none`,
                bg: `characterPrimaryBackground`,
              },
            }}
          >
            <pre
              sx={{
                my: 0,
                fontSize: `1.5rem`,
                lineHeight: `1.2`,
                fontFamily: `monospace`,
                letterSpacing: `0.04em`,
                color: `characterPrimaryText`,
              }}
            >
              <div>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
              </div>
              <div sx={{ bg: `hsl(200deg 60% 92%)` }}>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}>~</span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 92%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <b> Head</b>
              </div>
              <div sx={{ bg: `hsl(200deg 60% 84%)` }}>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}>.</span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}>.</span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 84%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <b> Eyes</b>
              </div>
              <div sx={{ bg: `hsl(200deg 60% 76%)` }}>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}>L</span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 76%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <b> Nose</b>
              </div>
              <div sx={{ bg: `hsl(200deg 60% 68%)` }}>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}>*</span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}>—</span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}>*</span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 68%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <b> Mouth</b>
              </div>
              <div sx={{ bg: `hsl(200deg 60% 60%)` }}>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}>w</span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `hsl(200deg 60% 60%)`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <b> Chin</b>
              </div>
              <div>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
                <span sx={{ bg: `characterPrimaryBackground`, boxShadow: `0 0 0 1px` }}> </span>
              </div>
            </pre>
          </a>

          <ul
            sx={{
              display: `grid`,
              gap: 3,
              pl: 0,
              mt: 4,
              mb: 0,
              listStyleType: `none`,
            }}
          >
            <li>
              There are {new Intl.NumberFormat().format(getAmountOfCharacters())} generated and curated unique editions.
            </li>

            {/* TODO: Add example */}
            <li>
              Characters exist within their own smart contract (See an{' '}
              <a sx={{ color: `inherit` }} href="/characters/2254.html">
                example
              </a>
              ).
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default About
